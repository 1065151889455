@import 'mixins';
#header-section {
  height: 100vh;
  background-image: url(../images/bg-inc.jpg);
  background-size: cover;
  position: relative;
  .overlay {
    position: absolute;
    content: '';
    background-image: linear-gradient(75deg, rgba(26, 44, 121, 0.88) 10%, rgba(0, 175, 239, 0.88)) !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .info-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    h1 {
      color: white;
    }
    p {
      color: white;
      font-size: 1.25rem;
      max-width: 60vw;
      margin: 0 auto;
    }
    .button {
      background: white;
      color: rgba(26, 44, 121, 1);
      padding: 1rem 1.5rem;
      text-decoration: none;
      margin-top: 1rem;
      display: inline-block;
      font-size: .9rem;
      font-weight: 400;
      transition: all 500ms ease;
      border: 1px solid white;
      &:hover {
        border: 1px solid white;
        background: transparent;
        color: white;
      }
    }
    ul {
      padding-left: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
      flex-wrap: wrap;
      li {
        border: 1px dashed #ebebeb;
        border-radius: 4px;
        text-align: center;
        padding: 0.75rem;
        margin-bottom: 0.75rem;
        min-width: 130px;
        margin: .5rem 1rem;
        width: 15%;
        h3 {
          color: white;
          span {
            font-size: .9rem;
            font-weight: 300;
          }
        }
        p {
          font-size: 1rem;
        }
      }
    }
  }
  .move-down {
    width: 1.5rem;
    height: 1.5rem;
    color: white;
    position: absolute;
    bottom: 5rem;
    animation: bounce 1s infinite;
    @keyframes bounce {
      0%,
      100% {
        transform: translateY(-25%);
        animationTimingFunction: cubic-bezier(0.8, 0, 1, 1);
      }
      50% {
        transform: translateY(0);
        animationTimingFunction: cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }
}

.objective {
  .card {
    border: none;
    box-shadow: 0 .125rem 1rem rgba(0, 0, 0, .075);
    // border-top: 3px solid #00afef;
    border-radius: 0;
    transition: all 500ms ease-in;
    h3 {
      font-size: 1.5rem;
    }
  }
}